import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_01.avif";
import Image2 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_02.avif";
import Image3 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_03.avif";
import Image4 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_04.avif";
import Image5 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_05.avif";
import Image6 from "./../../assets/Images/services/service_specification/aiml/ai_ml_spe_07.avif";
import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const AIML_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "AI Strategy Development",
      description:
        "We guide you through the intricacies of AI strategy development, ensuring that AI Consulting 2 your AI initiatives align with your business objectives. Our consulting services help you identify opportunities, assess risks, and create a roadmap for AI adoption.",
      image: Image1,
    },
    {
      id: 2,
      title: "AI/ML Training and Support",
      description:
        "We offer comprehensive training and support to ensure that your team is well-equipped to manage and optimize AI systems. Our ongoing support services ensure that your AI solutions continue to perform optimally and deliver value.",
      image: Image2,
    },
    {
      id: 3,
      title: "Machine Learning Model Development",
      description:
        "We offer customized machine learning model development services tailored to your unique needs. Our experts design, develop, and deploy models that solve complex problems and enhance operational efficiency.",
      image: Image3,
    },
    {
      id: 4,
      title: "Intelligent Process Automation",
      description:
        "Revolutionize your workflows with cutting-edge process automation powered by AI. Our solutions integrate seamlessly into your existing systems, automating repetitive tasks, enhancing accuracy, and unlocking efficiency like never before.",
      image: Image4,
    },
    {
      id: 5,
      title: "Predictive Analytics Solutions",
      description:
        "Stay ahead of the curve with our predictive analytics services. By leveraging advanced AI algorithms, we provide actionable insights that help you forecast trends, anticipate customer needs, and make data-driven decisions with confidence.",
      image: Image5,
    },
    {
      id: 6,
      title: "Natural Language Processing (NLP) Services",
      description:
        "Unlock the power of human-like communication with our NLP solutions. From chatbots to sentiment analysis, we help you bridge the gap between human and machine interaction, delivering exceptional customer experiences.",
      image: Image6,
    }
    
  ];

  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
            <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
            AI & ML Development: Revolutionizing Industries with Intelligent Solutions
            </h5>
            <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
              We empower businesses worldwide to scale, transform, and gain a
              competitive edge with our expert AI Chatbot development services.
              As a leading AI Chatbot development company, we serve startups,
              enterprises, product companies, digital agencies, SMEs, and
              government entities, meeting diverse technology needs. Our
              commitment positions us among the top software development
              companies. Our team of experienced developers uses advanced AI
              technologies to create chatbots that enhance customer engagement,
              streamline operations, and drive business growth. By choosing us,
              you are investing in reliable, efficient, and intelligent chatbot
              solutions tailored to meet your unique business requirements.
            </p>
            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Our AI Consulting Solutions
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Hire a talented team of AI engineers, designers, and AI developers at Sterling Technolabs who are dedicated to leveraging their extensive experience to create custom AI software solutions tailored to your requirements.
          </p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AIML_specifications;
