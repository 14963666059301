import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/standard-quality-control-concept-m.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";

import custom1 from "../assets/Images/napkin_images/raifox_custom_dev_01.png";
import custom2 from "../assets/Images/napkin_images/raifox_custom_dev_02.png";

import {
  FaReact,
  FaAngular,
  FaVuejs,
  FaCss3Alt,
  FaHtml5,
  FaNodeJs,
  FaServer,
  FaDatabase,
  FaCloudUploadAlt,
  FaDocker,
  FaPhp,
  FaPython,
  FaDatabase as FaMongodb,
  FaPostgresql,
  FaRedis,
} from "react-icons/fa";

import OurServices from "./OurServices";
import Custom_specifications from "./services_specifications/Custom_specifications";

const CustomDevelopment = () => {
  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            Custom Development
          </h1>
          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">Custom Development</span>
          </p>
        </div>
      </div>
        <Custom_specifications/>
      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <h2 className="text-3xl sm:text-3xl font-bold text-gray-800">
            Custom Development
          </h2>
       {/* Images Section */}
       <div className="space-y-4">
          <img
              src={custom1}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
          <img
              src={custom2}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
        </div>
        </div>
        <OurServices />
      </div>
      {/* Features Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16">
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Key Features of Custom Development
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Tailored Solutions",
                description:
                  "Build software that aligns perfectly with your business needs.",
              },
              {
                title: "Scalability",
                description:
                  "Easily adapt to growing demands and evolving technology trends.",
              },
              {
                title: "Seamless Integration",
                description:
                  "Ensure compatibility with existing systems and tools.",
              },
              {
                title: "Enhanced Security",
                description:
                  "Implement robust security measures tailored to your organization.",
              },
              {
                title: "Performance Optimization",
                description:
                  "Maximize efficiency and minimize resource consumption.",
              },
              {
                title: "Future-Ready",
                description:
                  "Incorporate cutting-edge technologies for long-term relevance.",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300"
              >
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h4>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 p-10">
        {/* Front-end Frameworks */}
        <div className="border p-6 rounded-lg shadow-md mb-6">
          <h1 className="text-2xl font-semibold mb-4">
            Front End Frameworks And Libraries
          </h1>
          <p className="text-gray-600 mb-4">
            The user-facing part of the application that users interact with.
            This layer usually includes HTML, CSS, JavaScript, and other
            front-end frameworks and libraries like React, Angular, Vue.js, etc.
          </p>
          <div className="grid grid-cols-4 gap-4">
            <FaHtml5 className="text-orange-500 text-6xl mx-auto" />
            <FaCss3Alt className="text-blue-400 text-6xl mx-auto" />
            <FaReact className="text-blue-500 text-6xl mx-auto" />
            <FaAngular className="text-red-500 text-6xl mx-auto" />
          </div>
        </div>

        {/* Back-end Layer */}
        <div className="border p-6 rounded-lg shadow-md mb-6">
          <h1 className="text-2xl font-semibold mb-4">Back-end Layer</h1>
          <p className="text-gray-600 mb-4">
            The storage layer of the application that stores and manages data.
            This layer includes popular database management systems like the
            below.
          </p>
          <div className="grid grid-cols-4 gap-4">
            <FaNodeJs className="text-green-600 text-6xl mx-auto" />
            <FaServer className="text-gray-800 text-6xl mx-auto" />{" "}
            {/* General Backend Server */}
            <FaPython className="text-yellow-500 text-6xl mx-auto" />{" "}
            {/* Python */}
            <FaPhp className="text-purple-600 text-6xl mx-auto" /> {/* PHP */}
          </div>
        </div>

        {/* Databases */}
        <div className="border p-6 rounded-lg shadow-md mb-6">
          <h1 className="text-2xl font-semibold mb-4">Databases</h1>
          <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
            The server-side of the application is responsible for handling the
            core functionality, including data processing, storage, and
            retrieval. It ensures seamless communication between the front end
            and databases such as <strong>MongoDB</strong>,{" "}
            <strong>MySQL</strong>, and <strong>PostgreSQL</strong>. The
            server-side optimizes data flow and supports dynamic interactions
            between the user interface and backend services, ensuring a smooth
            and efficient experience for users.
          </p>

          <div className="grid grid-cols-4 gap-4">
            {/* <FaMysql className="text-blue-700 text-6xl mx-auto" /> MySQL */}
            {/* <FaMongodb className="text-green-600 text-6xl mx-auto" /> {/* MongoDB */}
            {/* <FaPostgresql className="text-blue-500 text-6xl mx-auto" /> PostgreSQL */}
            {/* <FaDatabase className="text-yellow-600 text-6xl mx-auto" /> SQLite (generic icon) */}
            {/* <FaRedis className="text-red-600 text-6xl mx-auto" /> Redis */}
          </div>
        </div>

        {/* Hosting and Deployment */}
        <div className="border p-6 rounded-lg shadow-md">
          <h1 className="text-2xl font-semibold mb-4">
            Hosting and Deployment
          </h1>
          <p className="text-gray-600 mb-4">
            Processes and tools used to manage and automate the deployment,
            monitoring, and maintenance of software applications.
          </p>
          <div className="grid grid-cols-4 gap-4">
            <FaCloudUploadAlt className="text-orange-500 text-6xl mx-auto" />{" "}
            {/* AWS */}
            <FaCloudUploadAlt className="text-blue-500 text-6xl mx-auto" />{" "}
            {/* Azure */}
            <FaDocker className="text-blue-400 text-6xl mx-auto" />
            <FaCloudUploadAlt className="text-green-500 text-6xl mx-auto" />{" "}
            {/* Google Cloud */}
            {/* <FaServer className="text-gray-600 text-6xl mx-auto" /> Server */}
          </div>
        </div>
      </div>

      {/* Costing Section */}
      <div className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold mb-6">
            Cost Considerations for Custom Development
          </h3>
          <p className="text-gray-400 text-lg mb-6">
            The cost of custom development depends on project scope, complexity,
            and technology stack. Typical cost factors include:
          </p>
          <ul className="list-disc space-y-3 text-gray-400 text-left pl-5">
            <li>
              <strong>Basic Custom Solutions:</strong> $10,000 to $50,000 for
              small projects with minimal features.
            </li>
            <li>
              <strong>Mid-Range Projects:</strong> $50,000 to $150,000 for
              medium-scale solutions with integrations.
            </li>
            <li>
              <strong>Enterprise-Level Development:</strong> $150,000+ for
              large-scale, complex systems.
            </li>
          </ul>
          <p className="mt-4">
            Note: Costs include analysis, development, testing, deployment, and
            ongoing maintenance.
          </p>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default CustomDevelopment;
