import React from "react";
import Cert1 from "../assets/Images/certifications/RAIFOX IT - IEC-1.png";
import Cert2 from "../assets/Images/certifications/StartUp Raifox-1.png";
import Cert3 from "../assets/Images/certifications/MSME Raifox-1.png";
import Cert4 from "../assets/Images/certifications/ISO Raifox-1.png";

const Certifications = () => {
  const certifications = [
    { id: 1, image: Cert1 },
    { id: 2, image: Cert2 },
    { id: 3, image: Cert3 },
    { id: 4, image: Cert4 },
  ];

  return (
    <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header Section */}
      <div className="text-center mb-12 px-4">
        <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
          Our Certifications
        </h3>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Building Trust with{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-textColor to-blue-400">
            Proven Expertise
          </span>
        </h2>
        <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Discover our certifications that demonstrate our commitment to
          excellence and industry standards.
        </p>
      </div>

      {/* Certifications Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 sm:px-8">
        {certifications.map((cert) => (
          <div
            key={cert.id}
            className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-500 hover:scale-105 flex items-center justify-center"
          >
            {/* Certification Image */}
            <div className="p-2 lg:p-4">
              <img
                src={cert.image}
                alt={`Certification ${cert.id}`}
                className="w-full h-auto max-h-[600px] object-contain"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certifications;
