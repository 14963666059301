import React from "react";
import envoiceimg from "../assets/Images/Icons/einv-banner-web.png";
import { useNavigate } from "react-router-dom";

const EInvoicingCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-white mt-10 mb-10 px-4 md:px-16 lg:px-32 rounded-xl border-2 p-6 flex flex-col lg:flex-row items-center max-w-5xl mx-auto">
      {/* Left Content */}
      <div className="lg:w-1/2 text-center lg:text-left space-y-4">
        <h2
          className="text-xl md:text-2xl lg:text-3xl font-bold text-blue-600 relative 
          after:content-[''] after:absolute after:left-0 after:bottom-0 
          after:w-0 after:h-[2px] after:bg-blue-600 
          after:transition-all after:duration-500 
          hover:after:w-full"
        >
          E-Invoicing
        </h2>
        <p className="text-gray-600 text-sm md:text-base lg:text-lg">
          Leveraging RPA for Efficient Document
        </p>
        <button
          className="bg-blue-600 text-white px-6 md:px-8 py-4 rounded-2xl md:rounded-3xl text-xs md:text-sm hover:bg-blue-700"
          onClick={() => handleButtonClick()}
        >
          E-Invoicing
        </button>
      </div>

      {/* Right Content - Image Section */}
      <div className="lg:w-1/2 mt-6 lg:mt-0">
        <img
          src={envoiceimg}
          alt="Laptop Illustration"
          className="w-full max-w-xs md:max-w-md lg:max-w-lg mx-auto lg:mx-0"
        />
      </div>
    </div>
  );
};

export default EInvoicingCard;
