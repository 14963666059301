import React from "react";
import CONTACTUS from "../assets/Images/Icons/mnexgen_contactus (1).webp";

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-10 to-gray-200 flex items-center justify-center py-12 px-6">
   <div className="bg-white shadow-lg rounded-lg overflow-hidden grid grid-cols-1 md:grid-cols-2 max-w-6xl w-full p-10 sm:px-6 sm:mt-6">
  {/* Content Section */}
  <div className="pr-6 pl-4 sm:px-0 flex flex-col justify-center sm:py-6">
    {/* Added vertical padding */}
    <h1>
      <span className="text-3xl font-bold text-[#1E40AF] mb-6 transition duration-300 ease-in-out transform hover:text-[#1D4ED8] hover:scale-105 hover:shadow-lg">
        Get in Touch with Raifox
      </span>
    </h1>
    <div className="w-full max-w-full overflow-x-hidden">
      <p className="text-gray-600 leading-relaxed text-sm sm:text-base">
        At <strong>Raifox</strong>, we are always eager to connect with you.
        Whether you’re a customer with a requirement or an employee looking to
        reach out, our team is here to assist. We value every opportunity to
        engage, and we look forward to helping you with your digital
        transformation journey.
      </p>

      <p className="text-gray-600 leading-relaxed text-sm sm:text-base mt-3">
        Feel free to reach out to us through the following contact details:
      </p>
    </div>
    <ul className="text-gray-600 leading-relaxed text-base sm:text-lg mt-4 list-inside list-disc">
      <li>
        <strong>Email:</strong>{" "}
        <a href="mailto:info@raifox.com" className="text-blue-600">
          info@raifox.com
        </a>
      </li>
      <li>
        <strong>Phone:</strong> +91 8660330203
      </li>
      <li>
        <strong>WhatsApp:</strong>{" "}
        <a href="https://wa.me/918660330203" className="text-blue-600">
          Click here to chat
        </a>
      </li>
      <li>
        <strong>LinkedIn:</strong>{" "}
        <a
          href="https://www.linkedin.com/company/raifox-it-consultancy-pvt-ltd/posts/?feedView=all"
          className="text-blue-600"
          target="_blank"
          rel="noopener noreferrer"
        >
          Raifox LinkedIn
        </a>
      </li>
    </ul>
    <p className="text-gray-600 leading-relaxed text-base sm:text-lg mt-4">
      Our team will get back to you as soon as possible. We look forward to
      connecting with you!
    </p>
  </div>

  {/* Image Section */}
  <div className="flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-300">
    <img
      src={CONTACTUS}
      alt="Contact Us"
      className="w-full h-auto object-cover"
    />
  </div>
</div>

    </div>
  );
};

export default ContactUs;
