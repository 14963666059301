import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_01.png";
import Image2 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_02.png";
import Image3 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_03.png";
import Image4 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_04.png";
import Image5 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_05.png";
import Image6 from "./../../assets/Images/services/service_specification/digital_workforce/digital_workforce_06.png";


import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Digital_workforce_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "Digital Workforce Strategy & AI Consulting",
      description:
        "We collaborate with your enterprise to develop tailored AI strategies that optimize the digital workforce. Our consulting services ensure these strategies enhance operational efficiency, improve employee productivity, and support long-term business goals.",
      image: Image1,
    },
    {
      id: 2,
      title: "Enterprise-grade AI for Digital Workforce Management",
      description:
        "Our team designs custom AI applications that streamline workforce management, enhance collaboration, and improve overall efficiency within your organization. These AI solutions integrate seamlessly with your systems to boost employee performance and satisfaction.",
      image: Image2,
    },
    {
      id: 3,
      title: "Custom AI Solutions for Workforce Automation",
      description:
        "We create bespoke AI solutions that automate routine tasks, simplify workflows, and improve resource allocation. These solutions are tailored to your specific business needs, ensuring scalability and flexibility for future workforce growth.",
      image: Image3,
    },
    {
      id: 4,
      title: "Machine Learning Solutions for Workforce Insights",
      description:
        "Our machine learning solutions help analyze employee performance, uncover patterns, and predict trends within your workforce. These insights enable data-driven decision-making and drive business growth by enhancing workforce productivity.",
      image: Image4,
    },
    {
      id: 5,
      title: "Seamless Integration of AI with Workforce Tools",
      description:
        "We ensure your AI-driven solutions integrate smoothly with your existing workforce management systems. This integration optimizes resource utilization, improves operational processes, and enhances collaboration across departments.",
      image: Image5,
    },
    {
      id: 6,
      title: "Data Management & Analytics for Workforce Optimization",
      description:
        "Our data management and analytics solutions help enterprises manage large employee datasets effectively. We leverage AI-driven tools to enhance data accuracy, improve HR processes, and support data-driven decision-making for workforce management.",
      image: Image6,
    },
  ];
  
  
  
  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
          <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
  Global Leaders in Digital Workforce Solutions: Empowering Business Innovation and Productivity
</h5>
<p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
  We help businesses unlock the full potential of their digital workforce by integrating advanced technologies and innovative solutions. As a leading provider of digital workforce solutions, we serve startups, enterprises, SMEs, and government entities, addressing a wide variety of operational challenges. Our team of experts works with organizations to enhance workforce efficiency, automate processes, and drive productivity. By partnering with us, you're investing in scalable, secure, and customized solutions that optimize resource management, improve collaboration, and empower employees to thrive in a fast-paced, technology-driven world.
</p>




            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
    <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our Digital Workforce Solutions: Empowering Productivity and Innovation
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of digital workforce experts at Raifox who specialize in leveraging cutting-edge technologies to optimize your workforce. We create custom digital workforce solutions tailored to your business needs, from automating routine tasks to enhancing collaboration and optimizing resource allocation. Our team ensures your digital workforce initiatives are seamless, scalable, and designed to improve operational efficiency, empower employees, and drive innovation across your organization.
</p>




        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Digital_workforce_specifications;
