import React from "react";
import WHATSAPP from "../assets/Images/Icons/whatsapp.png";
import LINKDIN from "../assets/Images/Icons/linkedin.png";

const FloatingIcons = () => {
  return (
    <div className="fixed bottom-5 right-5 flex flex-row gap-4 z-50">
      <a
        href="https://wa.me/8660330203"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white p-3 rounded-full shadow-lg hover:scale-110 transition-transform"
      >
        <img src={WHATSAPP} alt="WhatsApp" className="w-8 h-8" />
      </a>
      <a
        href="https://www.linkedin.com/company/raifox-it-consultancy-pvt-ltd/posts/?feedView=all"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white p-3 rounded-full shadow-lg hover:scale-110 transition-transform"
      >
        <img src={LINKDIN} alt="LinkedIn" className="w-8 h-8" />
      </a>
    </div>
  );
};

export default FloatingIcons;
