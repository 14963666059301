import React from "react";
import RIGHTARROW from "../assets/Images/Icons/right-arrow.png";
import EMAIL from "../assets/Images/Icons/email.png";
import PHONE from "../assets/Images/Icons/phone.png";
import LOCATION from "../assets/Images/Icons/location.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const handleButtonClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-[#0c1224] text-white py-12 px-6 md:px-16 lg:px-32 relative">
      {/* Header Section */}
      <div className="text-center lg:text-left space-y-4 mb-8">
        <h2 className="text-3xl font-bold text-blue-500 animate-pulse">
          RAIFOX
        </h2>
        <p className="text-sm md:text-lg">
          Process consultants with a care to identify the root cause and deliver
          efficient solutions.
        </p>
      </div>

      {/* Call to Action Banner */}
      <div className="bg-gradient-to-r from-blue-500 via-purple-600 to-blue-500 py-6 px-8 rounded-lg mb-12 shadow-lg overflow-hidden relative">
        <h3 className="text-center text-xl md:text-2xl font-bold text-white">
          <span className="animate-slide">
            Any Custom Development Solutions? ✦ Looking For Collaboration?
          </span>
        </h3>
      </div>

      {/* Main Content Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 text-sm">
        {/* About Company */}
        <div>
          <h4 className="text-lg font-semibold mb-4 underline decoration-blue-500">
            ABOUT COMPANY
          </h4>
          <p className="leading-relaxed text-base">
            At RAIFOX, we specialize in offering innovative digital
            transformation solutions, including Robotic Process Automation,
            Chatbots, ERP Practices, and Custom Development. Our services are
            designed to optimize business processes, drive efficiency, and
            enhance overall performance. Reach out to us for customized,
            future-ready solutions tailored to your business needs." This keeps
            the message concise while maintaining professionalism.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h4 className="text-lg font-semibold mb-4 underline decoration-blue-500">
            QUICK LINKS
          </h4>
          <ul className="space-y-3">
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/BlogList"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Latest Blogs
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/contact"}
                className="hover:text-blue-400 transition duration-300 flex items-center  text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Careers
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/contact"}
                className="hover:text-blue-400 transition duration-300 flex items-center  text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* Services */}
        <div>
          <h4 className="text-lg font-semibold mb-4 underline decoration-blue-500">
            SERVICES
          </h4>
          <ul className="space-y-3">
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/ai_development"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                AI/ML Development
              </Link>
            </li>

            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/cloud_computing"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Cloud Computing
              </Link>
            </li>

            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/digitaltrasformation"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Digital Transformation
              </Link>
            </li>

            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/RoboticProcessAutomation"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Robotic Process Automation
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/ERPPractice"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                ERP Practice (Oracle)
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/Chatbots"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Chatbots
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/CustomDevelopment"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Custom Development
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/DigitalWorkforce"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Digital Workforce
              </Link>
            </li>
            <li onClick={() => handleButtonClick()}>
              <Link
                to={"/blockchain_development"}
                className="hover:text-blue-400 transition duration-300 flex items-center text-base"
              >
                <img
                  src={RIGHTARROW}
                  alt="Bullet Icon"
                  className="w-4 h-4 mr-2"
                />
                Blockchain Development
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4 underline decoration-blue-500">
            CONTACT US
          </h4>
          <ul className="space-y-3">
            <li>
              <span className="hover:text-blue-400 transition duration-300 flex items-center text-xl">
                <img src={EMAIL} alt="Bullet Icon" className="w-6 h-6 mr-2" />
                info@raifox.com
              </span>
            </li>
            <li>
              <span className="hover:text-blue-400 transition duration-300 flex items-center text-xl">
                <img src={PHONE} alt="Bullet Icon" className="w-6 h-6 mr-2" />
                +91 8660330203
              </span>
            </li>
            <li>
              <span className="hover:text-blue-400 transition duration-300 flex text-xl">
                <img
                  src={LOCATION}
                  alt="Bullet Icon"
                  className="w-6 h-6 mr-2"
                />
                Property Bearing No. 129/28/11b 2, 3, 2nd Floor, Papareddy
                palya, Nagarbhavi 2nd Stage, Bengaluru - 560072 India
              </span>
            </li>
          </ul>
        </div>
        {/* Map Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4 underline decoration-blue-500">
            LOCATION
          </h4>
          <iframe
            className="rounded-lg border-2 border-gray-700 shadow-md w-full h-52"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31713.92657100986!2d101.60734692424124!3d3.046591495511364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcd4ddfd44405%3A0x300dddf20a6bfa4b!Raifox%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1682076483691!5m2!1sen!2smy"
            allowFullScreen
            loading="lazy"
            title="Map"
          ></iframe>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-16 text-center text-sm text-gray-400">
        <p>
          Copyright © {currentYear}{" "}
          <span className="text-blue-500 font-semibold">RAIFOX</span>. All
          Rights Reserved.
        </p>
      </div>

      {/* Background Decoration */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-10 pointer-events-none"></div>
    </footer>
  );
};

export default Footer;
