import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/standard-quality-control-concept-m.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import { FaCheck } from "react-icons/fa";
import OurServices from "./OurServices";

import digi_workforce1 from "../assets/Images/napkin_images/raifox_blockchain_01.png";
import digi_workforce2 from "../assets/Images/napkin_images/raifox_blockchain_02.png";
import Digital_workforce_specifications from "./services_specifications/Digital_workforce_specifications";

const DigitalWorkforce = () => {
  return (
    <div className="overflow-x-hidden max-w-full">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            Digital Workforce
          </h1>
          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">Digital Workforce</span>
          </p>
        </div>
      </div>

      {/* Anir */}

      <Digital_workforce_specifications/>

      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <h2 className="text-3xl sm:text-3xl font-bold text-gray-800">
            Digital Workforce
          </h2>
          {/* Images Section */}
          <div className="space-y-4">
            <img
              src={digi_workforce1}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
            <img
              src={digi_workforce2}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
          </div>
        </div>
        <OurServices />
      </div>
      {/* Anir */}

      {/* Features Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16">
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Key Features of a Digital Workforce
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Automation",
                description:
                  "Streamline repetitive tasks and reduce manual effort.",
              },
              {
                title: "AI-Powered Insights",
                description: "Leverage AI for data-driven decision-making.",
              },
              {
                title: "Scalability",
                description: "Expand operations seamlessly with digital tools.",
              },
              {
                title: "Enhanced Collaboration",
                description:
                  "Foster teamwork with digital communication tools.",
              },
              {
                title: "Real-Time Monitoring",
                description: "Track progress and performance in real time.",
              },
              {
                title: "Personalization",
                description:
                  "Tailor solutions to meet business-specific needs.",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300"
              >
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h4>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto my-12 px-4 lg:px-16">
        {/* Main Flex Container */}
        <h1 className="text-4xl font-bold text-center py-8">
          Digital <span className="text-textColor">Acceleration</span> Services
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {/* Flexible Conversational Design Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              IT, Infosec & QA
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">IT Support Engineer</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Security Consultant</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Cloud Architect</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Oracle Apps DBA</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">DevOps Engineer</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Mainframe Developer</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Testers - Automated and Manual</p>
              </li>
            </ul>
          </div>

          {/* Train the AI (NLP Labeling) Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Software & Data Professionals
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Software Developer</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Technical Lead</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Back End Developer</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Architects</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Full stack Developers</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Front end Developers</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">ETL developers</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Data science engineers</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Analytics & Modelling</p>
              </li>
            </ul>
          </div>

          {/* Automation Flows Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Project Management
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Scrum Master</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Project Manager</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Change Manager</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Technical Project Manager</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Agile Project Manager</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Functional Business Analysts</p>
              </li>
            </ul>
          </div>
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Non IT roles
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Admin and HR</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Recruiters</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">SEO Engineers</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Marketing specialists</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Sales and Business Development leads
                </p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Operations Managers</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Costing Section */}
      <div className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold mb-6">
            Cost Considerations for a Digital Workforce
          </h3>
          <p className="text-gray-400 text-lg mb-6">
            The cost of building a digital workforce varies based on the size
            and complexity of the organization. Key cost factors include:
          </p>
          <ul className="list-disc space-y-3 text-gray-400 text-left pl-5">
            <li>
              <strong>Basic Automation:</strong> $10,000 to $30,000 for
              small-scale implementations.
            </li>
            <li>
              <strong>Comprehensive AI Solutions:</strong> $50,000 to $200,000
              for medium-scale projects.
            </li>
            <li>
              <strong>Enterprise-Level Digital Workforce:</strong> $200,000+ for
              large-scale integrations and customization.
            </li>
          </ul>
          <p className="mt-4">
            Note: Costs include implementation, training, maintenance, and
            support.
          </p>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default DigitalWorkforce;
