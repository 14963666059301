import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_01.avif";
import Image2 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_02.avif";
import Image3 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_03.avif";
import Image4 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_04.avif";
import Image5 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_05.avif";
import Image6 from "./../../assets/Images/services/service_specification/chatbot/chatbot_spe_06.avif";
import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Chatbots_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "AI Chatbot Technology Consulting",
      description:
        "We guide you through the complexities of AI chatbot technology, ensuring a smooth implementation process. Our expert consulting services align with your specific business goals to maximize impact and efficiency, helping you fully leverage the potential of AI chatbots.",
      image: Image1,
    },
    {
      id: 2,
      title: "AI Chatbot UI UX Design",
      description:
        "We design intuitive and user-friendly chatbot interfaces that significantly enhance user experience and engagement. Our designs are customized to align perfectly with your brand’s unique identity and requirements, ensuring a seamless and enjoyable user interaction. ",
      image: Image2,
    },
    {
      id: 3,
      title: "AI Chatbot Development",
      description:
        "We offer bespoke AI chatbot development services to create intelligent, efficient chatbots that streamline operations, enhance customer interactions, and drive overall business productivity. Our tailored solutions ensure that your chatbots meet your specific business needs. ",
      image: Image3,
    },
    {
      id: 4,
      title: "GPT-based Chatbot Development",
      description:
        "We develop advanced GPT-based chatbots that provide natural, accurate conversational experiences. Utilising the latest AI language models, our chatbots ensure high-quality interactions and user satisfaction, making your customer service more effective and engaging.",
      image: Image4,
    },
    {
      id: 5,
      title: "Chatbot API Integration",
      description:
        "We provide seamless integration of chatbots with your existing systems through robust API solutions. Our integration services ensure smooth, efficient operations and enhanced system functionality, allowing your chatbots to work harmoniously with your current setup.",
      image: Image5,
    },
    {
      id: 6,
      title: "Chatbot Maintenance and Support",
      description:
        "We deliver comprehensive maintenance and support services to keep your chatbots operating smoothly. Our ongoing support ensures consistent performance, reliability, and optimal functionality, allowing you to focus on your core business activities.",
      image: Image6,
    },
  ];

  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
            <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
              Global Leaders in Blockchain Development: Pioneering Innovation
              and Digital Transformation
            </h5>
            <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
            We empower businesses worldwide to unlock the potential of AI-powered chatbots and revolutionize their customer service with our expert chatbot development services. As a leading chatbot development company, we serve startups, enterprises, product companies, digital agencies, SMEs, and government entities, addressing a broad spectrum of customer interaction needs. Our commitment places us among the top software development companies. Our team of skilled developers uses advanced AI technologies to create intelligent, conversational chatbots that enhance customer engagement, automate routine tasks, and provide 24/7 support. By partnering with us, you are investing in cutting-edge, scalable, and innovative chatbot solutions that improve customer satisfaction, streamline operations, and drive your business growth.
            </p>
            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
         <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our Chatbot Consulting Solutions
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of AI engineers, developers, and chatbot consultants at Raifox who are dedicated to leveraging their expertise in conversational AI technologies. We create custom chatbot solutions tailored to your business needs, from developing intelligent customer support bots to automating routine tasks and enhancing user interactions. Our team ensures your chatbot initiatives are innovative, reliable, and designed to improve customer engagement, streamline communication, and provide seamless support for your operations.
</p>

        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Chatbots_specifications;
