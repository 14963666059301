import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/standard-quality-control-concept-m.jpg";
import { Link, useNavigate } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import ECOSYSTEM from "../assets/Images/Icons/trees.png";
import organizational from "../assets/Images/Icons/organizational.png";
import analysis from "../assets/Images/Icons/data-analysis.png";
import happy from "../assets/Images/Icons/happy.png";
import idea from "../assets/Images/Icons/idea.png";
import people from "../assets/Images/Icons/people.png";
import virtual from "../assets/Images/Icons/virtual-reality.png";
import group from "../assets/Images/Icons/group.png";
import OurServices from "./OurServices";
import blockChain1 from "../assets/Images/napkin_images/raifox_blockchain_01.png";
import blockChain2 from "../assets/Images/napkin_images/raifox_blockchain_02.png";
import Blockchain_specifications from "./services_specifications/Blockchain_specifications";

const BlockchainDevelopment = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            Blockchain Development
          </h1>

          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">Blockchain Development</span>
          </p>
        </div>
      </div>
          <Blockchain_specifications/>
      {/* Why Digital Transformation Section */}
      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          {/* <h2 className="text-3xl sm:text-4xl font-bold text-gray-800">
            Blockchain Development
          </h2> */}
           {/* Images Section */}
           <div className="space-y-4">
          <img
              src={blockChain1}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
          <img
              src={blockChain2}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
        </div>
        </div>
        <OurServices />
      </div>

      {/* Our Expertise Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold text-gray-800 mb-6">
            Our Expertise in Blockchain Development
          </h3>
          <p className="text-gray-600 text-lg mb-6">
            With years of experience, our team provides comprehensive digital
            transformation strategies across various domains.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              { title: "AI & Machine Learning", icon: "🤖" },
              { title: "Process Automation", icon: "⚙️" },
              { title: "Cloud Computing", icon: "☁️" },
              { title: "Cybersecurity", icon: "🔒" },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
              >
                <div className="text-4xl mb-4">{item.icon}</div>
                <h4 className="text-lg font-semibold text-gray-800">
                  {item.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* New Section with Cards */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold text-gray-800 mb-6">
            Key Areas of Focus
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              { title: "Ecosystem", image: ECOSYSTEM },
              { title: "Organization Culture", image: organizational },
              { title: "Data and Insights", image: analysis },
              { title: "Customer Experience", image: happy },
              { title: "Innovation", image: idea },
              { title: "Technology", image: virtual },
              { title: "People & Skills", image: people },
              { title: "Best Quality Support", image: group },
            ].map((card, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className=" h-20 object-cover rounded-lg mb-4"
                />
                <h4 className="text-lg font-semibold text-gray-800">
                  {card.title}
                </h4>
                <button
                  className="mt-2 px-4 sm:px-6 py-2 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-blue-600 hover:text-white hover:shadow-lg transform transition-all duration-300"
                  // onClick={() => navigate(service.route)}
                >
                  Read More →
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-[rgb(59,130,246)] text-white py-12">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            Join the league of top organizations embracing digital
            transformation. Let us help you navigate the journey.
          </p>
          <Link
            onClick={handleButtonClick()}
            className="px-6 py-3 bg-light-400 text-blue-900 rounded-lg shadow-lg bg-white font-semibold transform hover:scale-105 transition-transform duration-300"
          >
            Get Started Today
          </Link>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default BlockchainDevelopment;
