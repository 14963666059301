import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/standard-quality-control-concept-m.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import ORACLE from "../assets/Images/Icons/oracle.png";

import erp1 from "../assets/Images/napkin_images/raifox_ERP_01.png";
import erp2 from "../assets/Images/napkin_images/raifox_ERP_02.png";

import {
  FaChartBar,
  FaClipboardCheck,
  FaSyncAlt,
  FaGlobe,
  FaFileInvoice,
  FaDatabase,
} from "react-icons/fa";
import OurServices from "./OurServices";
import ERP_specifications from "./services_specifications/ERP_specifications";

const ERPPractice = () => {
  const descriptions = [
    {
      text: "Improved Financial Planning Analysis And Effective Decision Making",
      icon: <FaChartBar />,
    },
    {
      text: "Complete The Budgeting And Forecasting Cycle In Improved And Optimized UI",
      icon: <FaClipboardCheck />,
    },
    {
      text: "Automated Reconciliation & Compliance For Balance Sheet Account With Source GL",
      icon: <FaSyncAlt />,
    },
    {
      text: "Consolidation Solution That Will Accommodate The Multi Currency, Multi GAAP, IFRS Compliance",
      icon: <FaGlobe />,
    },
    {
      text: "P&L, BS, Cash Flow Report & Other Financial Statements Can Be Generated & Filed Per Local Tax Laws",
      icon: <FaFileInvoice />,
    },
    {
      text: "Master Data Management And Governance To Maintain CoA For All Downstream Systems",
      icon: <FaDatabase />,
    },
  ];
  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            ERP Solutions
          </h1>
          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">ERP Solutions</span>
          </p>
        </div>
      </div>

      {/* Anir */}

      <ERP_specifications/>

      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <h2 className="text-3xl sm:text-3xl font-bold text-gray-800">
            ERP Solutions
          </h2>

        {/* Images Section */}
        <div className="space-y-4">
          <img
              src={erp1}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
          <img
              src={erp2}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
        </div>
        </div>
        <OurServices />
      </div>
      {/* Anir */}

     

      {/* Features Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16">
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Key Features of ERP Systems
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Centralized Data Management",
                description:
                  "Streamline data from all departments into one system.",
              },
              {
                title: "Real-Time Reporting",
                description: "Generate accurate, real-time business insights.",
              },
              {
                title: "Scalability",
                description: "Adapt to the growing needs of your business.",
              },
              {
                title: "Integration Capabilities",
                description:
                  "Connect with existing software and tools for seamless operations.",
              },
              {
                title: "Automation",
                description:
                  "Reduce manual tasks and improve efficiency with automated workflows.",
              },
              {
                title: "Enhanced Security",
                description:
                  "Protect sensitive business data with advanced security features.",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300"
              >
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h4>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className=" text-white py-10 px-5">
        {/* Header Section */}
        <div className="flex flex-col items-center justify-center mb-10">
          <img
            src={ORACLE}
            alt="Oracle Logo"
            className="max-w-3xl h-16 transform transition-transform duration-300 hover:rotate-12"
          />
          <h1 className="text-3xl font-bold mt-4 uppercase text-black">
            ERP Practice
          </h1>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {descriptions.map((item, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-b from-gray-900 to-black rounded-lg shadow-lg overflow-hidden p-6 group"
            >
              <div className="relative z-10 flex flex-col items-center justify-center text-center">
                {/* Centered Icon */}
                <div className="text-white text-4xl mb-4 transform transition-transform duration-300 group-hover:rotate-12">
                  {item.icon}
                </div>

                {/* Text */}
                <p className="text-2xl font-medium transition-colors duration-300 group-hover:text-blue-500">
                  {item.text}
                </p>
              </div>

              {/* Hover Blur Effect */}
              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300 blur-md"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Costing Section */}
      <div className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold mb-6">
            Cost Considerations for ERP
          </h3>
          <p className="text-gray-400 text-lg mb-6">
            The cost of implementing an ERP system depends on the organization's
            size, industry, and specific requirements. Typical cost factors
            include:
          </p>
          <ul className="list-disc space-y-3 text-gray-400 text-left pl-5">
            <li>
              <strong>Basic ERP Solutions:</strong> $5,000 to $20,000 for small
              businesses with limited features.
            </li>
            <li>
              <strong>Mid-Range ERP Systems:</strong> $20,000 to $100,000 for
              businesses requiring advanced features and integrations.
            </li>
            <li>
              <strong>Enterprise-Level ERP Systems:</strong> $100,000+ for large
              organizations with complex requirements.
            </li>
          </ul>
          <p className="mt-4">
            Note: Costs also include customization, training, and ongoing
            support.
          </p>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default ERPPractice;
