import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_01.png";
import Image2 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_02.png";
import Image3 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_03.png";
import Image4 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_04.png";
import Image5 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_05.png";
import Image6 from "./../../assets/Images/services/service_specification/digital_transformation/digital_transformation_06.png";


import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Digital_transform_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "Digital Strategy Consulting",
      description:
        "We guide your business through the digital transformation journey, offering strategic insights and tailored solutions that align with your business goals. Our consulting services ensure that technology adoption enhances efficiency, innovation, and business growth.",
      image: Image1,
    },
    {
      id: 2,
      title: "Cloud Migration Services",
      description:
        "We assist businesses in seamlessly migrating to the cloud, optimizing operations, and enhancing scalability. Our cloud migration services ensure a smooth transition, enabling businesses to leverage cloud technology for improved collaboration, security, and flexibility.",
      image: Image2,
    },
    {
      id: 3,
      title: "Business Process Automation",
      description:
        "Our digital transformation solutions include automating manual business processes, driving operational efficiency and reducing costs. We implement intelligent automation strategies tailored to your specific needs, empowering your business with enhanced productivity.",
      image: Image3,
    },
    {
      id: 4,
      title: "Data Analytics and Insights",
      description:
        "Harness the power of data to make informed decisions with our data analytics services. We help businesses unlock actionable insights from their data, enabling predictive analysis, improving decision-making, and enhancing business strategies.",
      image: Image4,
    },
    {
      id: 5,
      title: "Custom Software Development",
      description:
        "Our team develops custom software solutions that cater to your unique business needs, driving digital transformation and improving operational efficiency. From application development to system integrations, we provide scalable and reliable software to support your growth.",
      image: Image5,
    },
    {
      id: 6,
      title: "IoT Solutions for Business Transformation",
      description:
        "We help businesses integrate IoT (Internet of Things) technologies to enhance connectivity, streamline operations, and collect real-time data. Our IoT solutions empower businesses to optimize resources, improve customer experiences, and create new revenue streams.",
      image: Image6,
    },
  ];
  

  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
          <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
  Global Leaders in Digital Transformation: Pioneering Innovation and Empowering Business Growth
</h5>
<p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
  We empower businesses worldwide to unlock the potential of digital technologies and revolutionize their operations with our expert digital transformation services. As a leading digital transformation company, we serve startups, enterprises, product companies, digital agencies, SMEs, and government entities, addressing a broad spectrum of business needs. Our commitment places us among the top technology solution providers. Our team of skilled consultants and developers utilizes cutting-edge technologies such as AI, cloud computing, and automation to drive innovation, enhance operational efficiency, and streamline business processes. By partnering with us, you are investing in scalable, secure, and customized solutions that not only improve business performance but also foster long-term growth and competitiveness in a rapidly evolving digital world.
</p>

            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
       <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our Digital Transformation Consulting Solutions
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of digital transformation consultants, engineers, and developers at Raifox who are dedicated to leveraging their expertise in advanced digital technologies. We create custom digital transformation solutions tailored to your business needs, from modernizing legacy systems to implementing AI, automation, and cloud computing. Our team ensures your digital transformation initiatives are seamless, scalable, and designed to enhance operational efficiency, improve customer experiences, and drive innovation across your organization.
</p>


        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Digital_transform_specifications;
