import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/people-office-analyzing-checking-finance-graphs.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import { FaCheck } from "react-icons/fa";
import OurServices from "./OurServices";

import chatBot1 from "../assets/Images/napkin_images/raifox_chatbot_01.png";
import chatBot2 from "../assets/Images/napkin_images/raifox_chatbot_02.png";
import Chatbots_specifications from "./services_specifications/Chatbots_specifications";

const ChatBots = () => {
  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            Chatbots
          </h1>
          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">Chatbots</span>
          </p>
        </div>
      </div>
      {/* Anir */}

      < Chatbots_specifications/>

      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <h2 className="text-3xl sm:text-3xl font-bold text-gray-800">
            Chatbots
          </h2>

          {/* Images Section */}
          <div className="space-y-4">
            <img
              src={chatBot1}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
            <img
              src={chatBot2}
              alt="AI/ML Service 1"
              className="rounded-lg w-full h-auto object-cover"
            />
          </div>
        </div>
        <OurServices />
      </div>
      {/* Anir */}

      {/* Features Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16">
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            Key Features of Chatbots
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Natural Language Understanding",
                description:
                  "Interact with users in a conversational and intuitive way.",
              },
              {
                title: "Multi-Channel Support",
                description:
                  "Operate seamlessly across platforms like websites, apps, and social media.",
              },
              {
                title: "AI-Powered Learning",
                description:
                  "Continuously improve through machine learning algorithms.",
              },
              {
                title: "Integration Capabilities",
                description:
                  "Connect with APIs, CRMs, and other tools for enhanced functionality.",
              },
              {
                title: "Voice and Text Interaction",
                description:
                  "Support both text and voice communication for user convenience.",
              },
              {
                title: "Customizable Workflows",
                description:
                  "Tailor conversation flows to meet specific business needs.",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300"
              >
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h4>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto my-12 px-4 lg:px-16">
        {/* Main Flex Container */}
        <div className="flex flex-wrap justify-between gap-8">
          {/* Flexible Conversational Design Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Flexible Conversational Design
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Craft response messages</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Embed “natural” pauses</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Variations in responses</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Multiple responses</p>
              </li>
            </ul>
          </div>

          {/* Train the AI (NLP Labeling) Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Train the AI (NLP Labeling)
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Easily add utterance examples for the AI engines to learn from
                </p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Review and continued training/improvement
                </p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Identify missing intents to continue improving the bot's
                  ability to meet customer requests
                </p>
              </li>
            </ul>
          </div>

          {/* Automation Flows Section */}
          <div className="flex-1 min-w-[280px]">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Automation Flows
            </h2>
            <ul className="space-y-6">
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Capture data, trigger integrations, send mails, launch RPA
                  bots, and other actions
                </p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">Conditional/situational actions</p>
              </li>
              <li className="flex items-center space-x-4">
                <FaCheck className="w-6 h-6 text-green-500" />
                <p className="text-gray-700">
                  Trigger requests for missing information
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Costing Section */}
      <div className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold mb-6">
            Cost Considerations for Chatbots
          </h3>
          <p className="text-gray-400 text-lg mb-6">
            The cost of implementing a chatbot varies based on its complexity
            and features. Here are typical cost factors:
          </p>
          <ul className="list-disc space-y-3 text-gray-400 text-left pl-5">
            <li>
              <strong>Basic Chatbots:</strong> $3,000 to $10,000 for simple,
              rule-based bots.
            </li>
            <li>
              <strong>Intermediate Chatbots:</strong> $10,000 to $30,000 for
              bots with AI and integration capabilities.
            </li>
            <li>
              <strong>Advanced Chatbots:</strong> $30,000+ for bots with
              advanced AI, multi-channel support, and voice recognition.
            </li>
          </ul>
          <p className="mt-4">
            Note: Costs also include maintenance, updates, and server hosting.
          </p>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default ChatBots;
