import React from "react";
import { Link, useNavigate } from "react-router-dom";
// Dummy Images
import aiml from "../assets/Images/services/12.png";
import rpa from "../assets/Images/services/raifox_rpa_icon.jpg";
import blockchain from "../assets/Images/services/blockchain.png";
import Cloud_computing from "../assets/Images/services/raifox_cloud_computing.png";
import digital_transformation from "../assets/Images/services/digital_tansformation.png";
import erpImage from "../assets/Images/services/raifox_erp.png";
import chatbotImage from "../assets/Images/Icons/chatbot.gif";
import customDevImage from "../assets/Images/services/raifox_custom_dev.png";
import digitalworkspace from "../assets/Images/services/raifox_dg_workforce.png";

const ServiceCards = () => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    window.scrollTo(0, 0);
  };

  const services = [
    {
      id: "01",
      title: "Digital Transformation",
      description:
        "Digital transformation leverages cutting-edge technology to revolutionize business processes, improve customer experience, and enhance organizational agility.",
      icon: "🌐",
      image: digital_transformation,
      route: "/digitaltrasformation",
    },
    {
      id: "02",
      title: "Robotic Process Automation",
      description:
        "Automate repetitive tasks and streamline workflows with RPA to increase efficiency, accuracy, and productivity across your organization.",
      icon: "🤖",
      image: rpa,
      route: "/RoboticProcessAutomation",
    },
    {
      id: "03",
      title: "Chatbots",
      description:
        "Enhance customer interactions with intelligent chatbots that provide instant support, automate queries, and improve user engagement.",
      icon: "💬",
      image: chatbotImage,
      route: "/ChatBots",
    },
    {
      id: "04",
      title: "ERP Solutions",
      description:
        "Optimize and manage your enterprise resources efficiently with Oracle's powerful ERP solutions tailored to your business needs.",
      icon: "📊",
      image: erpImage,
      route: "/ERPPractice",
    },
    {
      id: "05",
      title: "Custom Development",
      description:
        "Build tailored software solutions that align perfectly with your unique business requirements and objectives.",
      icon: "🛠️",
      image: customDevImage,
      route: "/CustomDevelopment",
    },
    {
      id: "06",
      title: "Digital Workforce",
      description:
        "Empower your organization with a digital workforce that seamlessly integrates RPA, AI, and other advanced technologies to drive innovation.",
      icon: "🤝",
      image: digitalworkspace,
      route: "/DigitalWorkforce",
    },
    {
      id: "07",
      title: "AI/ML Development",
      description:
        "Unlock the potential of AI and Machine Learning to transform your organization’s decision-making, predictive capabilities, and customer experiences. Seamlessly integrate AI/ML technologies into your operations, empowering your workforce to innovate and stay ahead in an evolving digital landscape.",
      icon: "💡",
      image: aiml,
      route: "/ai_development",
    },
    {
      id: "08",
      title: " Blockchain development",
      description:
        "Revolutionize your organization with Blockchain technology, offering enhanced security, transparency, and efficiency. By leveraging decentralized solutions, we enable seamless integration with your digital workforce, ensuring trust and innovation at the core of your operations.",
      icon: "🔗",
      image: blockchain,
      route: "/blockchain_development",
    },
    {
      id: "09",
      title: "Cloud Computing",
      description:
        "Optimize your business operations with scalable and secure Cloud Computing solutions. Empower your digital workforce with cloud-based technologies that enhance collaboration, flexibility, and innovation, ensuring your organization stays agile and competitive in the digital age.",
      icon: "☁️",
      image: Cloud_computing,
      route: "/cloud_computing",
    },
  ];

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-16 bg-gradient-to-r from-gray-50 to-gray-100 shadow-lg">
      {/* Section Heading */}
      <div className="text-center mb-12">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-800">
          Choose The Best{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
            IT Service
          </span>{" "}
          Company
        </h2>
      </div>

      {/* Service Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-6 lg:px-10">
        {services.map((service, index) => (
          <div
            key={index}
            className="relative group bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-all duration-300"
          >
            {/* Background Color Hover Effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-teal-500 opacity-0 group-hover:opacity-80 transition-opacity duration-500 rounded-xl"></div>

            {/* ID in the Background */}
            <div className="absolute top-5 right-5 text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-200 group-hover:opacity-20 transition-opacity duration-300 z-0">
              {service.id}
            </div>

            {/* Content */}
            <div className="relative z-20">
              {/* Icon */}
              <div className="flex items-center justify-center mt-8 sm:mt-10 mb-4 sm:mb-6">
                <div className="p-3 sm:p-4  shadow-md">
                  <span className="text-3xl sm:text-4xl text-white">
                    {/* {service.icon} */}

                    <img
                  src={service.image}
                  alt={service.title}
                  className="mx-auto mb-4 w-20 h-20 sm:w-24 sm:h-24 lg:w-20 lg:h-20 object-contain rounded-full"

                />
                  </span>
                </div>
              </div>

              {/* Title */}
              <div className="text-center px-4 sm:px-6">
                <h3 className="text-lg sm:text-xl font-bold text-gray-800 group-hover:text-white transition-colors duration-300 mb-2 sm:mb-4">
                  {service.title}
                </h3>
                <p className="text-gray-600 group-hover:text-white text-sm sm:text-base leading-relaxed transition-colors duration-300">
                  {service.description}
                </p>
              </div>

              {/* Button */}
              <div className="text-center mt-6 sm:mt-8 mb-6 sm:mb-10">
                <Link
                  onClick={handleOnclick}
                  to={service.route}
                  className="px-4 sm:px-6 py-2 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-blue-600 hover:text-white hover:shadow-lg transform transition-all duration-300"
                >
                  Read More →
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceCards;
