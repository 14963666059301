import React from "react";
import servicesbackgroundimage from "./../assets/Images/Banners/raifox_bg_3.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import TeamMember from "../components/TeamMember";
import Certifications from "../components/Certifications";
import WhoWeAre from "../components/WhoWeAre";
import WhyChooseUs from "../components/WhyChooseUs";
import OurClients from "../components/OurClients";

const AboutUs = () => {
  const teamMembers = [
    {
      id: 1,
      name: "John Doe",
      position: "CEO",
      image: "https://via.placeholder.com/150",
      description: "A visionary leader driving innovation and growth.",
    },
    {
      id: 2,
      name: "Jane Smith",
      position: "CTO",
      image: "https://via.placeholder.com/150",
      description: "Technical expertise and strategic thinking at its finest.",
    },
    {
      id: 3,
      name: "Emily Johnson",
      position: "COO",
      image: "https://via.placeholder.com/150",
      description: "Operational excellence and efficiency champion.",
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[450px] flex items-center justify-center text-center text-white"
        style={{
          backgroundImage: `url(${servicesbackgroundimage})`,
          backgroundSize: "cover", // Ensures the image covers the container without distortion
          backgroundPosition: "center center", // Centers the image in the container
          backgroundRepeat: "no-repeat", // Prevents repeating the background image
          height: "450px", // Keeps the height of the container fixed
          maxWidth: "100%", // Ensures the container takes full width
          objectFit: "cover", // Ensures the background image doesn't get stretched
        }}
      >
        <div className="bg-black bg-opacity-50 p-6 rounded-lg sm:px-4">
          <h1 className="text-4xl font-bold animate-fade-in-up">About Us</h1>
          <p className="mt-4 text-lg animate-fade-in-up">
            Learn more about our journey, mission, and team.
          </p>
        </div>
      </div>

      {/* Who We are */}
      {/* <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <WhoWeAre />
        </div>
      </div> */}
      {/* WhyChooseUs */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <WhyChooseUs />
        </div>
      </div>
      {/* OurClients */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <OurClients />
        </div>
      </div>
      {/* Team Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <TeamMember />
        </div>
      </div>
      {/* Certifications Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <Certifications />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
