import React from "react";
import Client1 from "../assets/Images/OurClients/15.png";
import Client2 from "../assets/Images/OurClients/16.png";
import Client3 from "../assets/Images/OurClients/17.png";
import Client4 from "../assets/Images/OurClients/18.png";
import Client5 from "../assets/Images/OurClients/19.png";
import Client6 from "../assets/Images/OurClients/20.png";
import Client7 from "../assets/Images/OurClients/21.png";
import Client8 from "../assets/Images/OurClients/22.png";
import Client9 from "../assets/Images/OurClients/23.png";
import Client10 from "../assets/Images/OurClients/24.png";
import Client11 from "../assets/Images/OurClients/25.png";
import Client12 from "../assets/Images/OurClients/26.png";
import Client13 from "../assets/Images/OurClients/27.png";
import Client14 from "../assets/Images/OurClients/28.png";
import Client15 from "../assets/Images/OurClients/29.png";
import Client16 from "../assets/Images/OurClients/30.png";
import Client17 from "../assets/Images/OurClients/31.png";
import Client18 from "../assets/Images/OurClients/32.png";
import Client19 from "../assets/Images/OurClients/33.png";
import Client20 from "../assets/Images/OurClients/34.png";
import Client21 from "../assets/Images/OurClients/35.png";
import Client22 from "../assets/Images/OurClients/36.png";
import Client23 from "../assets/Images/OurClients/37.png";

const OurClients = () => {
  const clients = [
    {
      id: 1,
      image: Client1,
    },
    {
      id: 2,
      image: Client2,
    },
    {
      id: 3,
      image: Client3,
    },
    {
      id: 4,
      image: Client4,
    },
    {
      id: 5,
      image: Client5,
    },
    {
      id: 6,
      image: Client6,
    },
    {
      id: 7,
      image: Client7,
    },
    {
      id: 8,
      image: Client8,
    },
    {
      id: 9,
      image: Client9,
    },
    {
      id: 10,
      image: Client10,
    },
    {
      id: 11,
      image: Client11,
    },
    {
      id: 12,
      image: Client12,
    },
    {
      id: 13,
      image: Client13,
    },
    {
      id: 14,
      image: Client14,
    },
    {
      id: 15,
      image: Client15,
    },
    {
      id: 16,
      image: Client16,
    },
    {
      id: 17,
      image: Client17,
    },
    {
      id: 18,
      image: Client18,
    },
    {
      id: 19,
      image: Client19,
    },
    {
      id: 20,
      image: Client20,
    },
    {
      id: 21,
      image: Client21,
    },
    {
      id: 22,
      image: Client22,
    },
    {
      id: 23,
      image: Client23,
    },
  ];

  return (
    <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header Section */}
      <div className="text-center mb-12 px-4">
        <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
          Our Clients
        </h3>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Trusted by{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-textColor to-blue-400">
            Industry Leaders
          </span>
        </h2>
        <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Discover the companies that trust us to drive their digital
          transformation and innovation.
        </p>
      </div>

      {/* Clients Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-8">
        {clients.map((client) => (
          <div
            key={client.id}
            className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500 flex justify-center items-center"
          >
            {/* Client Logo */}
            <img
              src={client.image}
              alt={`Client ${client.id}`}
              className="w-32 h-32 object-contain"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurClients;
