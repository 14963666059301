import React from "react";
import Image1 from "../assets/Images/why_choose_us/38.png";
import Image2 from "../assets/Images/why_choose_us/39.png";
import Image3 from "../assets/Images/why_choose_us/40.png";
import Image4 from "../assets/Images/why_choose_us/41.png";
import Image5 from "../assets/Images/why_choose_us/42.png";
import Image6 from "../assets/Images/why_choose_us/43.png";

const WhyChooseUs = () => {
  const cards = [
    {
      id: 1,
      title: "Commitment to Innovation and Excellence",
      description:
        "RaiFox IT Consultancy stands out through a steadfast commitment to innovation, excellence, and client success. We offer a comprehensive suite of services, driven by industry certifications, strategic insights, and a client-first mindset that consistently delivers superior results.",
      image: Image1,
    },
    {
      id: 2,
      title: "Global Standards Compliance",
      description:
        "Certified by ISO and IEC, RaiFox ensures that our solutions uphold the highest international standards for quality, security and performance. This certification enables businesses to scale confidently and reliably",
      image: Image2,
    },
    {
      id: 3,
      title: "MSME Certified",
      description:
        "As a certified MSME, RaiFox provides costeffective, impactful solutions that empower small businesses and startups in India to accelerate their digital transformation, fostering economic growth and innovation within the nation.",
      image: Image3,
    },
    {
      id: 4,
      title: "Startup Innovation",
      description:
        "Recognized for our startup agility and creativity, RaiFox excels in pushing technological boundaries. Our innovative approach results in cutting-edge, tailored solutions that keep our clients at the forefront of the digital era",
      image: Image4,
    },
    {
      id: 5,
      title: "Tailored Solutions for Every Business",
      description:
        "Our services are specifically customized to meet the unique needs of each client, ensuring scalable and impactful solutions that align perfectly with business objectives and deliver measurable outcomes.",
      image: Image5,
    },
    {
      id: 6,
      title: "End-to-End Expertise",
      description:
        "RaiFox provides comprehensive support across all stages of the digital transformation journey—from strategy and consulting to execution and optimization. We ensure seamless integration and sustained success for our clients.",
      image: Image6,
    },
  ];

  return (
    <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header Section */}
      <div className="text-center mb-12 px-4">
        <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
          Why Choose Us
        </h3>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Excellence in{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-textColor to-blue-400">
            Every Aspect
          </span>
        </h2>
        <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Explore the core values and strengths that set RaiFox apart as a
          trusted IT partner.
        </p>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
          >
            {/* Image */}
            <div className="flex justify-center mb-4">
              <img
                src={card.image}
                alt={card.title}
                className="w-20 h-20 object-contain"
              />
            </div>
            {/* Title */}
            <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
              {card.title}
            </h3>
            {/* Description */}
            <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
              {card.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
