import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/people-office-analyzing-checking-finance-graphs.jpg";
import { Link, useNavigate } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import { FaCheck } from "react-icons/fa";

const OurServices = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    //  navigate('/contact')
    window.scrollTo(0, 0);
  };

  const services = [
    { name: "AI/ML Development", path: "/ai_development" },
    { name: "Blockchain Development", path: "/blockchain_development" },
    { name: "Cloud Computing", path: "/cloud_computing" },
    { name: "Digital Transformation", path: "/digitaltrasformation" },
    { name: "Robotic Process Automation", path: "/roboticprocessautomation" },
    { name: "ERP Practice (Oracle)", path: "/ERPPractice" },
    { name: "Chatbots", path: "/ChatBots" },
    { name: "Custom Development", path: "/CustomDevelopment" },
    { name: "Digital Workforce", path: "/DigitalWorkforce" },
  ];
  return (
    <div>
      <div className="bg-white shadow-lg rounded-xl p-6 sticky top-10">
        <h3 className="text-2xl font-bold text-gray-800 mb-4">Our Services</h3>
        <ul className="space-y-4">
          {services.map((service, index) => (
            <li key={index}>
              <Link
                to={service.path} // Use the path from the object
                onClick={() => handleButtonClick()} // Call handleButtonClick if necessary
                className="flex justify-between items-center bg-gray-100 hover:bg-gray-200 p-4 rounded-lg shadow-md transform hover:-translate-y-1 transition-transform duration-200"
              >
                {service.name} <span>→</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OurServices;
