import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/block_chain/block_chain_01.png";
import Image2 from "./../../assets/Images/services/service_specification/block_chain/block_chain_02.png";
import Image3 from "./../../assets/Images/services/service_specification/block_chain/block_chain_03.png";
import Image4 from "./../../assets/Images/services/service_specification/block_chain/block_chain_04.png";
import Image5 from "./../../assets/Images/services/service_specification/block_chain/block_chain_05.png";
import Image6 from "./../../assets/Images/services/service_specification/block_chain/block_chain_06.png";


import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Blockchain_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "Blockchain Integration Solutions",
      description:
        "Transform your business with secure, transparent, and decentralized blockchain technology. Our solutions enable seamless integration into your existing systems, enhancing data integrity and reducing operational risks.",
      image: Image1,
    },
    {
      id: 2,
      title: "Smart Contract Development",
      description:
        "Automate and secure your transactions with custom smart contracts. Our blockchain-powered contracts eliminate intermediaries, ensuring faster, more reliable, and tamper-proof agreements.",
      image: Image2,
    },
    {
      id: 3,
      title: "Blockchain for Supply Chain Management",
      description:
        "Optimize your supply chain operations with blockchain’s real-time traceability and transparency. Our solutions provide end-to-end visibility, reduce fraud, and improve trust between parties.",
      image: Image3,
    },
    {
      id: 4,
      title: "Cryptocurrency Solutions",
      description:
        "Tap into the world of digital currencies with our comprehensive cryptocurrency services. From wallet management to trading solutions, we help you navigate the complexities of the crypto market.",
      image: Image4,
    },
    {
      id: 5,
      title: "Decentralized Applications (dApps)",
      description:
        "Build powerful and secure decentralized applications with blockchain. We design dApps that operate on a peer-to-peer network, ensuring high availability and eliminating single points of failure.",
      image: Image5,
    },
    {
      id: 6,
      title: "Blockchain Consulting & Strategy",
      description:
        "Leverage our expertise to drive your blockchain initiatives. Our consulting services guide you through the complexities of blockchain adoption, helping you identify use cases and develop a tailored strategy for implementation.",
      image: Image6,
    },
  ];

  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
            <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
              Global Leaders in Blockchain Development: Pioneering Innovation
              and Digital Transformation
            </h5>
            <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
              We empower businesses worldwide to unlock the potential of
              blockchain technology and achieve secure, transparent, and
              decentralized solutions with our expert blockchain development
              services. As a leading blockchain development company, we serve
              startups, enterprises, product companies, digital agencies, SMEs,
              and government entities, addressing a wide range of industry
              needs. Our commitment places us among the top software development
              companies. Our team of skilled developers leverages cutting-edge
              blockchain technologies to create secure systems, automate
              processes through smart contracts, and improve transparency across
              industries. By choosing us, you are investing in robust, scalable,
              and innovative blockchain solutions tailored to elevate your
              business operations, enhance trust, and drive digital
              transformation.
            </p>
            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Our Blockchain Consulting Solutions
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Hire a talented team of blockchain engineers, developers, and consultants at Raifox who are dedicated to leveraging their expertise in decentralized technologies. We create custom blockchain solutions tailored to your business needs, from developing smart contracts to building secure and scalable decentralized applications (dApps). Our team ensures your blockchain initiatives are innovative, reliable, and designed to enhance transparency, efficiency, and security in your operations.
          </p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Blockchain_specifications;
