import React from "react";
import Carousel from "./public/components/Carousel";
import PlanSlider from "./public/components/PlanSlider";
import ServiceCards from "./public/components/ServiceCards";
import TeamMember from "./public/components/TeamMember";
import WhoWeAre from "./public/components/WhoWeAre";
import WhyChooseUs from "./public/components/WhyChooseUs";
import OurClients from "./public/components/OurClients";
import Certifications from "./public/components/Certifications";
import BlogList from "./public/services/BlogList";

const Home = () => {
  return (
    <div>
      <Carousel />
      {/* <WhoWeAre/> */}
      <WhyChooseUs/>
      <PlanSlider />
      <ServiceCards />
      <OurClients/>
      <TeamMember />
      <BlogList/>
      <Certifications/>
    </div>
  );
};

export default Home;
