import React from "react";
import servicesbackgroundimage from "../assets/Images/Banners/standard-quality-control-concept-m.jpg";
import { Link, useNavigate } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import ECOSYSTEM from "../assets/Images/Icons/trees.png";
import organizational from "../assets/Images/Icons/organizational.png";
import analysis from "../assets/Images/Icons/data-analysis.png";
import happy from "../assets/Images/Icons/happy.png";
import idea from "../assets/Images/Icons/idea.png";
import people from "../assets/Images/Icons/people.png";
import virtual from "../assets/Images/Icons/virtual-reality.png";
import group from "../assets/Images/Icons/group.png";
import OurServices from "./OurServices";
const Ml_Development = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[350px] lg:h-[450px] flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${servicesbackgroundimage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black bg-opacity-75"></div>
        <div className="relative z-10 space-y-4 px-4">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-extrabold tracking-tight">
            Digital Transformation
          </h1>

          <p className="text-sm sm:text-lg">
            <Link to="/" className="hover:underline text-gray-300">
              Home
            </Link>
            <span className="mx-2">»</span>
            <span className="text-white">Digital Transformation</span>
          </p>
        </div>
      </div>

      {/* Why Digital Transformation Section */}
      <div className="container mx-auto my-12 px-4 lg:px-16 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800">
            Digital Transformation
          </h2>
          <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
            Raifox empowers businesses to navigate and excel in the era of{" "}
            <strong>Digital Transformation</strong> by integrating modern
            technologies like{" "}
            <strong>AI, automation, and cloud solutions</strong>. We help
            organizations reimagine their operations, unlocking new
            opportunities for innovation, agility, and efficiency. By automating
            processes, optimizing workflows, and enhancing customer engagement,
            we drive significant improvements that help businesses achieve
            sustainable growth and a competitive edge in the market. Our
            approach is tailored to each client's needs, ensuring seamless
            integration across all aspects of their business.
          </p>

          <p className="text-gray-600 leading-relaxed text-base sm:text-lg mt-4">
            Raifox has been a trusted partner for leading organizations like{" "}
            <strong>Facebook (Meta)</strong>, <strong>Amway</strong>,{" "}
            <strong>JPA</strong>, <strong>HRDF</strong>, and many others,
            guiding them through transformative digital journeys. These
            initiatives focus on the strategic use of{" "}
            <strong>
              AI, automation, process improvements, and application
              rationalization
            </strong>
            , enabling businesses to enhance operational efficiency, improve
            decision-making, and better serve their customers. Our services also
            include comprehensive digital strategy development, change
            management, and post-implementation support to ensure long-term
            success and measurable results.
          </p>

          <ul className="list-disc text-gray-700 space-y-3 pl-5">
            <li>
              <span className="text-gray-800 font-medium">
                Assessment of the entire organization to identify the current
                state of digital journey
              </span>{" "}
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Assessment of awareness and willingness to adopt new
                technologies{" "}
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Recommendation on technology adoption and provide digital
                roadmap to the organization
              </span>{" "}
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Evaluation of existing workflows and systems to identify
                opportunities for automation and efficiency improvements.
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Design and implementation of scalable, future-ready digital
                solutions tailored to business goals.
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Measurement of organizational readiness to embrace digital
                culture and adapt to AI-driven processes.
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Creation of a phased transformation plan focusing on quick wins
                and long-term digital sustainability.
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Identification of technology gaps and integration strategies to
                enhance customer and operational experiences.
              </span>
            </li>
            <li>
              <span className="text-gray-800 font-medium">
                Enabling seamless integration of advanced technologies to
                optimize processes and enhance decision-making capabilities
                across the organization.
              </span>
            </li>
          </ul>
        </div>
        <OurServices />
      </div>

      {/* Our Expertise Section */}
      <div className="bg-blue-50 py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold text-gray-800 mb-6">
            Our Expertise in Digital Transformation
          </h3>
          <p className="text-gray-600 text-lg mb-6">
            With years of experience, our team provides comprehensive digital
            transformation strategies across various domains.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              { title: "AI & Machine Learning", icon: "🤖" },
              { title: "Process Automation", icon: "⚙️" },
              { title: "Cloud Computing", icon: "☁️" },
              { title: "Cybersecurity", icon: "🔒" },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
              >
                <div className="text-4xl mb-4">{item.icon}</div>
                <h4 className="text-lg font-semibold text-gray-800">
                  {item.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* New Section with Cards */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 lg:px-16 text-center">
          <h3 className="text-3xl font-bold text-gray-800 mb-6">
            Key Areas of Focus
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              { title: "Ecosystem", image: ECOSYSTEM },
              { title: "Organization Culture", image: organizational },
              { title: "Data and Insights", image: analysis },
              { title: "Customer Experience", image: happy },
              { title: "Innovation", image: idea },
              { title: "Technology", image: virtual },
              { title: "People & Skills", image: people },
              { title: "Best Quality Support", image: group },
            ].map((card, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className=" h-20 object-cover rounded-lg mb-4"
                />
                <h4 className="text-lg font-semibold text-gray-800">
                  {card.title}
                </h4>
                <button
                  className="mt-2 px-4 sm:px-6 py-2 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-blue-600 hover:text-white hover:shadow-lg transform transition-all duration-300"
                  // onClick={() => navigate(service.route)}
                >
                  Read More →
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-[rgb(59,130,246)] text-white py-12">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            Join the league of top organizations embracing digital
            transformation. Let us help you navigate the journey.
          </p>
          <Link
            onClick={handleButtonClick()}
            className="px-6 py-3 bg-light-400 text-blue-900 rounded-lg shadow-lg bg-white font-semibold transform hover:scale-105 transition-transform duration-300"
          >
            Get Started Today
          </Link>
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default Ml_Development;
