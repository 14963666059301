import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/RPA/RPA_01.png";
import Image2 from "./../../assets/Images/services/service_specification/RPA/RPA_02.png";
import Image3 from "./../../assets/Images/services/service_specification/RPA/RPA_03.png";
import Image4 from "./../../assets/Images/services/service_specification/RPA/RPA_04.png";
import Image5 from "./../../assets/Images/services/service_specification/RPA/RPA_05.png";
import Image6 from "./../../assets/Images/services/service_specification/RPA/RPA_06.png";



import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const RPA_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "RPA Strategy Consulting",
      description:
        "We guide your business through the automation journey, offering strategic insights and tailored RPA solutions that align with your operational goals. Our consulting services ensure technology adoption drives efficiency, cost savings, and enhanced business performance.",
      image: Image1,
    },
    {
      id: 2,
      title: "End-to-End RPA Development",
      description:
        "Our team specializes in designing and implementing end-to-end RPA solutions, automating your business processes from start to finish. We help you build robust RPA workflows that reduce manual intervention, increase accuracy, and enhance operational productivity.",
      image: Image2,
    },
    {
      id: 3,
      title: "RPA Integration Services",
      description:
        "We seamlessly integrate RPA tools with your existing systems, ensuring smooth and efficient automation across all processes. Our integration services optimize resource allocation, enhance process execution, and provide real-time insights for improved decision-making.",
      image: Image3,
    },
    {
      id: 4,
      title: "Intelligent Process Automation (IPA)",
      description:
        "We go beyond basic RPA by incorporating AI and machine learning technologies into your automation processes. Our intelligent process automation solutions enable cognitive decision-making, document processing, and smart workflows for a truly advanced automation experience.",
      image: Image4,
    },
    {
      id: 5,
      title: "RPA for Compliance & Risk Management",
      description:
        "Our RPA solutions ensure your business meets regulatory requirements and reduces risks by automating compliance tasks. We help automate audits, reporting, and document management, improving accuracy and reducing the risk of human error.",
      image: Image5,
    },
    {
      id: 6,
      title: "RPA Maintenance & Support",
      description:
        "We offer ongoing support and maintenance for your RPA solutions, ensuring optimal performance, scalability, and continuous improvements. Our team monitors and updates your RPA systems to keep them running efficiently and aligned with your evolving business needs.",
      image: Image6,
    },
  ];
  
  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
          <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
  Global Leaders in Robotic Process Automation: Pioneering Innovation and Empowering Business Efficiency
</h5>
<p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
  We empower businesses worldwide to unlock the full potential of Robotic Process Automation (RPA) and revolutionize their operations with our expert automation services. As a leading RPA solution provider, we serve startups, enterprises, product companies, digital agencies, SMEs, and government entities, addressing a wide array of business needs. Our commitment places us among the top technology solution providers. Our team of skilled RPA consultants and developers leverages advanced automation tools to streamline processes, reduce costs, and increase accuracy and productivity across various industries. By partnering with us, you are investing in scalable, secure, and customized RPA solutions that improve operational efficiency, reduce manual workloads, and drive long-term growth in a rapidly evolving digital landscape.
</p>


            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
       <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our RPA (Robotic Process Automation) Consulting Solutions
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of RPA consultants, engineers, and developers at Raifox who are dedicated to leveraging their expertise in process automation technologies. We create custom RPA solutions tailored to your business needs, from automating routine tasks to streamlining complex workflows and integrating AI-driven decision-making. Our team ensures your RPA initiatives are seamless, scalable, and designed to enhance operational efficiency, reduce manual errors, and drive productivity across your organization.
</p>



        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default RPA_specifications;
