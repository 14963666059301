import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_01.png";
import Image2 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_02.png";
import Image3 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_03.png";
import Image4 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_04.png";
import Image5 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_05.png";
import Image6 from "./../../assets/Images/services/service_specification/cloud/cloud_spe_06.png";


import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Cloud_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "Cloud Migration Solutions",
      description:
        "Seamlessly migrate your business to the cloud with our expert migration services. We ensure minimal disruption, efficient data transfer, and secure cloud deployment tailored to your unique requirements.",
      image: Image1,
    },
    {
      id: 2,
      title: "Cloud Infrastructure Management",
      description:
        "Optimize your cloud infrastructure with our end-to-end management services. From monitoring to scaling and securing your resources, we ensure maximum uptime, performance, and cost efficiency.",
      image: Image2,
    },
    {
      id: 3,
      title: "Multi-Cloud Strategy",
      description:
        "Leverage the power of multiple cloud providers with our multi-cloud strategy. We help you enhance flexibility, avoid vendor lock-in, and optimize workloads for improved resilience and scalability.",
      image: Image3,
    },
    {
      id: 4,
      title: "Cloud Security Solutions",
      description:
        "Protect your data and applications with our robust cloud security solutions. We offer end-to-end security measures including encryption, firewall protection, and identity management to safeguard your cloud environment.",
      image: Image4,
    },
    {
      id: 5,
      title: "Cloud Application Development",
      description:
        "Develop scalable and high-performance cloud applications with our expert team. We design applications that fully leverage cloud-native technologies to ensure seamless performance and global accessibility.",
      image: Image5,
    },
    {
      id: 6,
      title: "Cloud Cost Optimization",
      description:
        "Reduce your cloud spending with our cloud cost optimization services. We analyze your cloud usage, recommend cost-effective solutions, and implement strategies to ensure you get the most value from your cloud investment.",
      image: Image6,
    },
  ];
  
  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
            <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
            Global Leaders in Cloud Computing: Pioneering Innovation and Digital Transformation
            </h5>
            <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
            We empower businesses worldwide to unlock the full potential of cloud technologies, driving scalability, flexibility, and cost-efficiency with our expert cloud computing services. As a leading cloud computing solutions provider, we serve startups, enterprises, product companies, digital agencies, SMEs, and government organizations, addressing a diverse range of industry requirements. Our commitment places us among the top technology service providers. Our team of experienced cloud engineers leverages cutting-edge cloud platforms to build secure, scalable, and high-performance infrastructures, optimize resources, and streamline business operations. By partnering with us, you are investing in robust, future-ready cloud solutions designed to elevate your business, enhance operational agility, and fuel your digital transformation journey.
            </p>
            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
          Our Cloud Computing Consulting Solutions
          </h2>
          <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
          Hire a talented team of cloud engineers, architects, and consultants at Raifox who are dedicated to leveraging their expertise in cloud technologies. We create custom cloud solutions tailored to your business needs, from cloud migration to infrastructure management and security. Our team ensures your cloud initiatives are scalable, reliable, and designed to enhance flexibility, cost-efficiency, and operational agility. Whether it's developing cloud-native applications or optimizing your cloud infrastructure, we empower your business to maximize the potential of the cloud and drive your digital transformation forward.
          </p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Cloud_specifications;
