import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopNav from "./TopNav";
import logoImage from "./../assets/Images/raifox_logo.jpg";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close mobile menu when a link is clicked
  const closeMobileMenu = () => {
    window.scrollTo(0, 0);
    setIsMobileMenuOpen(false);
    setIsServicesDropdownOpen(false); // Close services dropdown if it's open
  };

  // Track scrolling to apply blur effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 0 Indexing

  const handleButtonClick = () => {
    toggleMobileMenu();
    window.scrollTo(0, 0);
  };

  // service toggle for mobile view
  const toggleServicesMenu = () => {
    setIsServicesMenuOpen(!isServicesMenuOpen);
  };

  return (
    <div onMouseLeave={() => setIsServicesDropdownOpen(false)}>
      {/* Static Navbar */}
      {/* <TopNav /> */}
      <nav
        className={`${
          isScrolled ? "backdrop-blur-lg bg-blue-900/30" : "bg-customBlue"
        } text-white px-3 md:px-36 py-5 fixed top-${
          isScrolled ? "0" : "18"
        } w-full z-50 transition-all duration-300 `}
      >
        <div className="container mx-auto flex justify-between items-center font-barlow">
          {/* Logo */}

          <Link
            to={"/"}
            onClick={handleButtonClick}
            className="text-3xl font-bold text-mainLogo"
          >
            <img
              src={logoImage} // Replace with the path to your logo image
              alt="Raifox Logo"
              className="max-w-[150px] max-h-[60px]" // Increased size
            />
          </Link>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-8 items-center text-black">
            <Link
              to={"/"}
              onClick={handleButtonClick}
              className="text-lg font-semibold text-gray-700 hover:text-blue-500 transition-colors duration-300"
            >
              Home
            </Link>

            {/* Services Dropdown */}
            <div
              className="relative group"
              onMouseEnter={() => setIsServicesDropdownOpen(true)}
              // onMouseLeave={() => setIsServicesDropdownOpen(false)}
            >
              <Link
                to={"/"}
                onClick={handleButtonClick}
                className="text-lg font-semibold text-gray-700 hover:text-blue-500 transition-colors duration-300"
              >
                Services
                <i className="fas fa-chevron-down ml-1"></i>
              </Link>
              {isServicesDropdownOpen && (
                <div
                  className="absolute bg-orange-100 top-full left-0 mt-6 bg-white text-black shadow-lg w-64 z-50"
                  onMouseEnter={() => setIsServicesDropdownOpen(true)}
                  onMouseLeave={() => setIsServicesDropdownOpen(true)}
                >
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/ai_development"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🧠 AI/ML Development
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/blockchain_development"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600 "
                      >
                        <span className="mx-1">₿</span> Blockchain Development
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/cloud_computing"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        ☁️ Cloud Computing
                      </Link>
                    </li>

                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/ChatBots"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🤖 Chatbots
                      </Link>
                    </li>

                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/digitaltrasformation"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🌐 Digital Transformation
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/RoboticProcessAutomation"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🦾 Robotic Process Automation
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/CustomDevelopment"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🧑🏻‍💻 Custom Development
                      </Link>
                    </li>

                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/ERPPractice"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        🗂️ ERP Solutions
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link
                        to={"/DigitalWorkforce"}
                        onClick={handleButtonClick}
                        className="block text-black hover:text-blue-600"
                      >
                        👨‍💻 Digital Workforce
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <Link
              to={"/BlogList"}
              onClick={handleButtonClick}
              className="text-lg font-semibold text-gray-700 hover:text-blue-500 transition-colors duration-300"
            >
              Blogs
            </Link>
            <Link
              to={"/AboutUs"}
              onClick={handleButtonClick}
              className="text-lg font-semibold text-gray-700 hover:text-blue-500 transition-colors duration-300"
            >
             About Us
            </Link>

            <Link
              to={"/contact"}
              onClick={handleButtonClick}
              className="hidden md:block px-6 py-2 text-lg font-semibold bg-blueDark text-white rounded-full hover:bg-black hover:text-white transition-colors duration-300"

            >
              Contact Us
            </Link>
          </div>

          {/* Mobile Menu Icon */}
          <div className="md:hidden flex items-center">
            <button
              className="text-xl"
              onClick={toggleMobileMenu}
              aria-label="Toggle mobile menu"
            >
              {isMobileMenuOpen ? (
                <i className="fas fa-times text-black"></i> // Close Icon
              ) : (
                <i className="fas fa-bars text-black"></i> // Menu Icon
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-gradient-to-r from-blue-500 to-teal-500 text-white mt-2 rounded-lg shadow-lg max-w-full">
            <ul className="flex flex-col space-y-4 p-4">
              <li>
                <Link
                  to={"/"}
                  onClick={closeMobileMenu}
                  className="text-lg font-semibold text-white hover:text-blue-300 md:text-gray-700 md:hover:text-blue-500 transition-colors duration-300"

                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/AboutUs"}
                  onClick={closeMobileMenu}
                  className="text-lg font-semibold text-white hover:text-blue-300 md:text-gray-700 md:hover:text-blue-500 transition-colors duration-300"

                >
                  About Us
                </Link>
              </li>
              <li>
                <div className="relative">
                  <button
                    // className="hover:text-blue-300 flex items-center w-full"
                    className="text-lg font-semibold text-white hover:text-blue-300 md:text-gray-700 md:hover:text-blue-500 transition-colors duration-300 "

                    onClick={toggleServicesMenu}
                  >
                    Services <i className="fas fa-chevron-down ml-1"></i>
                  </button>
                  {isServicesMenuOpen && (
                    <div className="absolute top-full left-0 mt-2 bg-white text-black shadow-lg rounded-lg w-80 z-50 overflow-y-scroll max-h-64">
                      <ul className="py-2">
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/ai_development"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🧠 AI/ML Development
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/blockchain_development"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600 ml-1"
                          >
                            <span className="mx-1">₿</span> Blockchain
                            Development
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/cloud_computing"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            ☁️ Cloud Computing
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/digitaltrasformation"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🌐 Digital Transformation
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/RoboticProcessAutomation"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🦾 Robotic Process Automation
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/chatbots"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🤖 Chatbots
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/CustomDevelopment"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🧑🏻‍💻 Custom Development
                          </Link>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/ERPPractice"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            🗂️ ERP Solutions
                          </Link>
                        </li>

                        <li className="px-4 py-2 hover:bg-gray-100">
                          <Link
                            to={"/DigitalWorkforce"}
                            onClick={() => {
                              closeMobileMenu();
                              setIsServicesMenuOpen(false); // Close the dropdown
                            }}
                            className="block text-black hover:text-blue-600"
                          >
                            👨‍💻 Digital Workforce
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <Link
                  to={"/BlogList"}
                  onClick={closeMobileMenu}
                  className="text-lg font-semibold text-white hover:text-blue-300 md:text-gray-700 md:hover:text-blue-500 transition-colors duration-300"
                  >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  to={"/contact"}
                  onClick={closeMobileMenu}
                  className="block px-6 py-2 border border-blue-300 rounded-full flex items-center justify-center hover:bg-blue-300 hover:text-blue-900 transition text-center"
                  >
                  <strong>Contact Us</strong>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
