import React, { useState, useEffect } from "react";
import banner1 from "../assets/Images/Banners/raifox_new_bg_01.avif";
import banner2 from "../assets/Images/Banners/raifox_new_bg_02.avif";
import banner3 from "../assets/Images/Banners/raifox_new_bg_03.avif";
import banner4 from "../assets/Images/Banners/raifox_new_bg_04.webp";
import "./Carousel.css";

const Carousel = () => {
  const slides = [
    {
      id: 1,
      image: banner1,
      title:
        "At Raifox, we drive business success through innovative solutions and cutting-edge technology.",
    },
    {
      id: 2,
      image: banner2,
      title:
        "Raifox redefines possibilities with AI, IoT, and cloud solutions. Transforming ideas into sustainable growth.",
    },
    {
      id: 3,
      image: banner3,
      title:
        "Where technology meets excellence, Raifox delivers tailored solutions for a smarter tomorrow.",
    },
    // {
    //   id: 4,
    //   image: banner4,
    //   title:
    //     "Where technology meets excellence, Raifox delivers tailored solutions for a smarter tomorrow.",
    // },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      goToNext();
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-full mx-auto">
      {/* Slide Container */}
      <div className="overflow-hidden relative">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {slides.map((slide) => (
            <div key={slide.id} className="flex-shrink-0 w-full relative">
              {/* Image */}
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full object-cover h-[300px] md:h-[350px] lg:h-[600px]"
              />

              {/* Animated Rings */}
              <div className="animated-rings">
                <div className="animated-ring"></div>
                <div className="animated-ring"></div>
                <div className="animated-ring"></div>
              </div>

              {/* Slide Title */}
              <div className="absolute inset-0 flex items-center justify-center text-center px-4 pt-16">
                <h2 className="text-white text-2xl md:text-4xl lg:text-6xl font-bold leading-snug max-w-[90%] md:max-w-[70%]">
                  {slide.title}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={goToPrevious}
        className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-blue-900 text-white p-2 md:p-3 rounded-full hover:bg-blue-700 transition"
      >
        ❮
      </button>
      <button
        onClick={goToNext}
        className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-blue-900 text-white p-2 md:p-3 rounded-full hover:bg-blue-700 transition"
      >
        ❯
      </button>

      {/* Indicators */}
      <div className="flex justify-center mt-4 space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-2 h-2 md:w-3 md:h-3 rounded-full ${
              currentIndex === index ? "bg-blue-900" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
