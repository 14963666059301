import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/ERP/erp_spe_01.avif";
import Image2 from "./../../assets/Images/services/service_specification/ERP/erp_spe_02.avif";
import Image3 from "./../../assets/Images/services/service_specification/ERP/erp_spe_03.avif";
import Image4 from "./../../assets/Images/services/service_specification/ERP/erp_spe_04.avif";
import Image5 from "./../../assets/Images/services/service_specification/ERP/erp_spe_05.avif";
import Image6 from "./../../assets/Images/services/service_specification/ERP/erp_spe_06.avif";


import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const ERP_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "ERP Strategy & AI Consulting",
      description:
        "We work closely with your enterprise to develop tailored AI strategies that integrate with your ERP system. Our consulting services ensure these strategies enhance operational efficiency, improve customer engagement, and support long-term business objectives.",
      image: Image1,
    },
    {
      id: 2,
      title: "Enterprise-grade AI Application Design",
      description:
        "Our team designs custom AI applications that seamlessly integrate with your ERP system. These applications are built to improve productivity, streamline operations, and provide intuitive user experiences across your organization.",
      image: Image2,
    },
    {
      id: 3,
      title: "Custom AI Solutions for ERP Optimization",
      description:
        "We create bespoke AI solutions that simplify complex enterprise processes and automate tasks within your ERP system. These solutions are designed to meet your specific business needs, ensuring scalability and flexibility for future growth.",
      image: Image3,
    },
    {
      id: 4,
      title: "Machine Learning Solutions for ERP Insights",
      description:
        "Our machine learning solutions help enterprises analyze large datasets within their ERP system, uncover patterns, and predict trends. These insights enable better decision-making and help drive business growth through advanced analytics.",
      image: Image4,
    },
    {
      id: 5,
      title: "Seamless ERP & AI System Integration",
      description:
        "We ensure your AI solutions integrate smoothly with your existing ERP system using robust APIs and middleware. This integration enhances overall system functionality, ensures interoperability, and streamlines operations across all departments.",
      image: Image5,
    },
    {
      id: 6,
      title: "Data Management & Analytics for ERP",
      description:
        "Our data management and analytics solutions help enterprises effectively manage large datasets within their ERP systems. We leverage AI-driven tools to enhance data accuracy and support data-driven decision-making processes.",
      image: Image6,
    },
  ];
  
  
  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
          <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
  Global Leaders in ERP Solutions: Empowering Business Transformation and Operational Efficiency
</h5>
<p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
  We help businesses unlock the full potential of Enterprise Resource Planning (ERP) systems and revolutionize their operations with our expert ERP solutions. As a leading ERP solution provider, we cater to startups, enterprises, product companies, digital agencies, SMEs, and government entities, addressing a wide range of business challenges. Our commitment places us among the top technology solution providers. Our team of skilled ERP consultants and developers uses advanced technologies to optimize workflows, reduce costs, and increase productivity across various sectors. By partnering with us, you're investing in scalable, secure, and customized ERP solutions that streamline processes, enhance data accuracy, and foster long-term growth in a rapidly evolving business landscape.
</p>



            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our ERP (Enterprise Resource Planning) Consulting Solutions
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of ERP consultants, engineers, and developers at Raifox who are dedicated to leveraging their expertise in enterprise resource planning technologies. We create custom ERP solutions tailored to your business needs, from streamlining core business processes to integrating data across departments and optimizing resource management. Our team ensures your ERP initiatives are seamless, scalable, and designed to enhance operational efficiency, improve decision-making, and drive business growth across your organization.
</p>




        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ERP_specifications;
