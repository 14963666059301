import React from "react";
import servicesbackgroundimage from "./assets/servicesbackgroundimage.jpg";
import { Link } from "react-router-dom";
import ProcessFlow from "../components/ProcessFlow";
import transformingChatbotImage from "./assets/raifox_blog_transforming_chatbot.jpeg";
import rpaBlog from "./assets/raifox_blog_rpa.png";
import digitaltransform from "./assets/raifox_blog_digital_transform.jpeg";
import erpblog from "./assets/raifox_blog_ERP.jpg";
import customDevBlog from "./assets/raifox_blog_custom_dev.webp";
import diWorkforce from "./assets/raifox-blog_di_workforce.jpg";

const BlogList = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Get current month (0-11)
  const currentYear = currentDate.getFullYear(); // Get current year (2024, etc.)

  // Helper function to generate dynamic publish date for each blog
  const getDynamicPublishDate = (daysAgo = 0) => {
    const blogDate = new Date(currentDate);
    blogDate.setDate(currentDate.getDate() - daysAgo); // Set the blog publish date relative to today
    return blogDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
  };

  const blogs = [
    {
      id: 1,
      title: "Transforming Customer Experience with Chatbots",
      description:
        "In the digital era, customers demand instant responses and personalized interactions. Raifox offers intelligent chatbot solutions that can handle customer queries 24/7, improve engagement, and boost satisfaction. Learn how integrating AI-powered chatbots into your business processes can reduce operational costs while enhancing customer experience and support efficiency.",
      image: transformingChatbotImage,
      link: "/blog/chatbots",
      publishDate: getDynamicPublishDate(2), // Published 2 days ago
    },
    {
      id: 2,
      title: "Revolutionizing Business Operations with RPA",
      description:
        "Robotic Process Automation (RPA) is transforming industries by automating repetitive tasks, enhancing efficiency, and reducing human error. At Raifox, we specialize in creating RPA solutions that streamline workflows and free up valuable time for your employees to focus on strategic initiatives. Explore the potential of RPA to drive productivity, innovation, and cost savings for your organization.",
      image: rpaBlog,
      link: "/blog/rpa",
      publishDate: getDynamicPublishDate(10), // Published 10 days ago
    },
    {
      id: 3,
      title: "Accelerating Your Digital Transformation Journey",
      description:
        "Digital transformation is essential for staying competitive in today’s fast-paced business world. Raifox guides businesses through their digital transformation journey, helping them embrace new technologies, optimize operations, and deliver better customer experiences. Learn how digital transformation can help you innovate, improve agility, and create sustainable growth in the digital age.",
      image: digitaltransform,
      link: "/blog/digital-transformation",
      publishDate: getDynamicPublishDate(15), // Published 15 days ago
    },
    {
      id: 4,
      title: "Boosting Business Efficiency with ERP Systems",
      description:
        "Enterprise Resource Planning (ERP) systems are critical for businesses looking to integrate and streamline their operations. Raifox provides end-to-end ERP solutions that help businesses manage everything from finance to supply chain operations. Discover how an ERP system can improve data accuracy, reduce redundancy, and give your organization real-time visibility into business processes.",
      image: erpblog,
      link: "/blog/erp",
      publishDate: getDynamicPublishDate(20), // Published 20 days ago
    },
    {
      id: 5,
      title: "Custom Development for Unique Business Needs",
      description:
        "Every business has its own unique challenges. Raifox specializes in custom development solutions tailored to your specific requirements. Whether it's building a web application, mobile app, or integrating existing systems, our team creates solutions that are scalable, secure, and align with your business objectives. Learn how custom development can give you a competitive edge by addressing specific needs and driving innovation.",
      image: customDevBlog,
      link: "/blog/custom-development",
      publishDate: getDynamicPublishDate(25), // Published 25 days ago
    },
    {
      id: 6,
      title: "Building a Digital Workforce for Future-Ready Organizations",
      description:
        "The future of work is digital, and Raifox is leading the way in building digital workforces that can automate tasks, enhance collaboration, and provide actionable insights. By leveraging AI, RPA, and other advanced technologies, we help businesses create digital teams that work alongside human employees to achieve greater efficiency, innovation, and success. Explore how a digital workforce can transform your business operations and drive long-term growth.",
      image: diWorkforce,
      link: "/blog/digital-workforce",
      publishDate: getDynamicPublishDate(30), // Published 30 days ago
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div 
  className="relative bg-cover bg-center h-0 sm:h-[50px] flex items-center pt-4 justify-center text-center text-white"
>
        {/* <div className="bg-black mt-6  bg-opacity-50 p-6 rounded-lg">
          <h3 className="text-4xl font-bold animate-fade-in-up">
            Welcome to Our Blog
          </h3>
          <p className="mt-4 text-lg animate-fade-in-up">
            Explore insights, tips, and stories to inspire your journey.
          </p>
        </div> */}
      </div>

      {/* Blog List Section with Top Margin */}
      <div className="container mx-auto px-6 py-12 mt-8">
        <h2 className="text-3xl font-bold text-center mb-8">Latest Blogs</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{blog.title}</h3>
                <p className="text-gray-600 mb-4">{blog.description}</p>
                <div className="flex justify-between items-center">
                  {/* Date on the left */}
                  <span className="text-gray-500 text-sm">
                    {blog.publishDate}
                  </span>

                  {/* Read More button on the right */}
                  <Link
                    to={blog.link}
                    className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Process Flow Section */}
      <ProcessFlow />
    </div>
  );
};

export default BlogList;
