import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image1 from "./../../assets/Images/services/service_specification/custom/custom_spe_01.avif";
import Image2 from "./../../assets/Images/services/service_specification/custom/custom_spe_02.avif";
import Image3 from "./../../assets/Images/services/service_specification/custom/custom_spe_03.avif";
import Image4 from "./../../assets/Images/services/service_specification/custom/custom_spe_04.avif";
import Image5 from "./../../assets/Images/services/service_specification/custom/custom_spe_05.avif";
import Image6 from "./../../assets/Images/services/service_specification/custom/custom_spe_06.png";
import Image7 from "./../../assets/Images/services/service_specification/custom/custom_spe_07.avif";
import Image8 from "./../../assets/Images/services/service_specification/custom/custom_spe_08.avif";
import service_sec from "./../../assets/Images/services/service_specification/raifox_service sec avtar.avif";

const Custom_specifications = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const cards = [
    {
      id: 1,
      title: "Custom Enterprise Solutions",
      description:
        "Our specialty is working with software development firms to create sector-specific solutions that link and simplify corporate operations in a way that maximizes output.",
      image: Image1,
    },
    {
      id: 2,
      title: "MVP Development",
      description:
        "Consider using Sterling's MVP Development to present your idea, improve it with first-rate services, and get important traction for your business.",
      image: Image2,
    },
    {
      id: 3,
      title: "SAAS Product Development",
      description:
        "We assist companies in identifying, creating, and putting into practice Software as a Service (SaaS) solutions—a critical first step toward modernization and digitization.",
      image: Image3,
    },
    {
      id: 4,
      title: "Mobile and Web Apps",
      description:
        "With our streamlined architecture, you can expedite app development and quickly create market-ready mobile and web apps for scalable platforms.",
      image: Image4,
    },
    {
      id: 5,
      title: "Cloud Solutions",
      description:
        "Savor the on-demand delivery of cloud solutions, including virtual desktops, servers, and storage, all supported by dedicated networks for dependable operation.",
      image: Image5,
    },
    {
      id: 6,
      title: "Data Science",
      description:
        "We give intelligent corporate data together with quantitative and qualitative insights to improve decision-making processes by outsourcing information from advanced analytics tools.",
      image: Image6,
    },
    {
      id: 7,
      title: "Q&A Testing",
      description:
        "We provide custom software development clients with high-quality engineering-enabled software testing services that are tailored to their specific needs.",
      image: Image7,
    },
    {
      id: 8,
      title: "Third-Party Integrations",
      description:
        "Manage business initiatives more effectively by combining third-party solutions and a variety of APIs to satisfy platform requirements and integration requirements.",
      image: Image8,
    },
  ];
  
  return (
    <>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Single Row Section */}
        <div className="flex flex-col lg:flex-row items-center gap-8">
          {/* Image Column */}
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              src={service_sec}
              alt="Chatbot Development"
              className="rounded-lg shadow-lg w-full max-w-md object-cover"
            />
          </div>
          {/* Text Column */}
          <div className="w-full lg:w-1/2">
          <h5 className="text-2xl sm:text-3xl md:text-2xl font-bold text-gray-800 mb-4">
  Global Leaders in Custom Software Development: Pioneering Innovation and Empowering Business Growth
</h5>
<p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
  We empower businesses worldwide to unlock the full potential of custom software development and revolutionize their operations with our expert development services. As a leading custom development solution provider, we serve startups, enterprises, product companies, digital agencies, SMEs, and government entities, addressing a wide array of business needs. Our commitment places us among the top technology solution providers. Our team of skilled developers leverages advanced technologies to create tailored solutions that streamline workflows, improve functionality, and drive operational excellence across various industries. By partnering with us, you are investing in scalable, secure, and customized software solutions that enhance business performance, foster innovation, and support long-term growth in an ever-evolving digital landscape.
</p>



            <Link
              onClick={handleButtonClick()}
              className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-all duration-300 font-semibold text-center"
            >
              GET A FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>
      <section className="py-12 px-4 sm:px-8 lg:px-16 bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Header Section */}
        <div className="text-center mb-12 px-4">
          {/* <h3 className="text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold uppercase tracking-wider text-blue-500 animate-pulse">
            Why Choose Us
          </h3> */}
       <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
  Our Custom Development Solutions
</h2>
<p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">
  Hire a talented team of custom development experts, engineers, and consultants at Raifox who specialize in creating tailored solutions for your business needs. From developing bespoke software applications to integrating advanced technologies, we focus on delivering high-quality, scalable solutions that drive business innovation. Our team ensures that your custom development initiatives are aligned with your objectives, optimizing workflows and enhancing productivity across your organization.
</p>



        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-500"
            >
              {/* Image */}
              <div className="flex justify-center mb-4">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-20 h-20 object-contain"
                />
              </div>
              {/* Title */}
              <h3 className="text-lg sm:text-xl font-bold text-gray-800 text-center mb-2">
                {card.title}
              </h3>
              {/* Description */}
              <p className="text-sm sm:text-base text-gray-600 text-center leading-relaxed">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Custom_specifications;
